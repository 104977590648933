import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import School from "../components/Gallery/School";
import "./Gallery.css";

//http://www.mpsckp.com/gallery/school/images/1.webp
const MpsGallery = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Gallery</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Router>
          <Switch>
            <Route path="/School">
              <School />
            </Route>
          </Switch>
        </Router>
        <Container>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ScienceExhibition2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/science_exhibition_2024/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ScienceExhibition2024"}>Science Exhibition 2024</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualSports2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_sports_2024/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualSports2024"}>Annual Sports 2024</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_2024/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay2024"}>Annual Day 2024</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Loih2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/loih_2024/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Loih2024"}>Learning Outcome (In House)</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/GansdhiShstri24"}>
                  <img
                    src="http://www.mpsckp.com/gallery/gandhi_shastri_24/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/GansdhiShstri24"}>
                    Gandhi Shashti Jyanti 2024
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/InHouseCareerGuidance"}>
                  <img
                    src="http://www.mpsckp.com/gallery/in_house_career_guidance/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/InHouseCareerGuidance"}>
                    In-House Career Guidance
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/CbseKhoKho"}>
                  <img
                    src="http://www.mpsckp.com/gallery/cbse_kho_kho/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/CbseKhoKho"}>
                    CBSE Kho Kho Tournament East Zone 2024
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/CbseFootball2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/cbse_football_2024/thumb.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/CbseFootball2024"}>
                    CBSE Football Tournament East Zone 2024
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_23/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay2023"}>Annual Day 2023</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualSports2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_sports_23/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualSports2023"}>Annual Sports 2023</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ClassroomManagement"}>
                  <img
                    src="http://www.mpsckp.com/gallery/cbp_cm/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ClassroomManagement"}>
                    CBP (Classroom Management)
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/CapacityBuildingProgram"}>
                  <img
                    src="http://www.mpsckp.com/gallery/CapacityBuildingProgram2023/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/CapacityBuildingProgram"}>
                    CBSE Training(CBP)
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/InHouseTraining"}>
                  <img
                    src="http://www.mpsckp.com/gallery/inhousetraining2023/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/InHouseTraining"}>CBSE In House Training </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Excursion2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/excursion2023/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Excursion2023"}>Excursion 2023</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/RoadSafety"}>
                  <img
                    src="http://www.mpsckp.com/gallery/road_safety/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/RoadSafety"}>Road Safety</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/RepublicDay2023"}>
                  <img
                    src="http://www.mpsckp.com/gallery/republic_day_23/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/RepublicDay2023"}>Republic Day 2023</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay2022"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay2022"}>Annual Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ScienceExhibition"}>
                  <img
                    src="http://www.mpsckp.com/gallery/science_exhibition_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ScienceExhibition"}>Science Exhibition 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/SportsDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/sports_day_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/SportsDay"}>Sports Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/IndependenceDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/id_2022/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/IndependenceDay"}>Independence Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ChildrenDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/children_day_22/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ChildrenDay"}>Children Day 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Farewell"}>
                  <img
                    src="http://www.mpsckp.com/gallery/farewell_2022/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Farewell"}>Farewell 2022</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Essay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/essay_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Essay"}>Essay</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Calligraphy"}>
                  <img
                    src="http://www.mpsckp.com/gallery/calligraphy_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Calligraphy"}>Calligraphy</Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/School"}>
                  <img
                    src="http://www.mpsckp.com/gallery/school/images/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/School"}>The School</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/RepublicDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/republic_day_20/2.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/RepublicDay"}>Republic Day</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/PrizeDistribution"}>
                  <img
                    src="http://www.mpsckp.com/gallery/prize_distribution_20/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/PrizeDistribution"}>Prize Distribution</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_19/thumb.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay"}>Annual Day</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Excurtion"}>
                  <img
                    src="http://www.mpsckp.com/gallery/excursion_2018/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Excursion"}>Excursion</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Rangoli"}>
                  <img
                    src="http://www.mpsckp.com/gallery/rangoli_17/3.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Rangoli"}>Rangoli</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MpsGallery;
