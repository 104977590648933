import React from "react";
import axios from "axios";
import Moment from "moment";

class GetTc extends React.Component {
  state = {
    users: [],
    isLoading: true,
    errors: null,
    filterName: "", // New state for the filter
    data: [], // Ensure data is initialized as an empty array
  };

  getNotices() {
    axios
      .get("https://mpsckp.com/gettc.php")
      .then((response) =>
        response.data.map((notice) => ({
          name: `${notice.name}`,
          dob: `${notice.dob}`,
          tid: `${notice.tid}`,
          file: `${notice.file}`,
          date: `${notice.date}`,
        }))
      )
      .then((data) => {
        this.setState({
          data, // Assign data to state
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getNotices();
  }

  handleFilterChange = (event) => {
    this.setState({ filterName: event.target.value });
  };

  render() {
    const { isLoading, data, filterName } = this.state;
    Moment.locale("en");

    // Ensure the data is filtered only if it's an array
    const filteredData = Array.isArray(data)
      ? data.filter((notice) =>
          notice.name.toLowerCase().includes(filterName.toLowerCase())
        )
      : [];

    return (
      <React.Fragment>
        <div className="filter-section" style={{ marginBottom: "20px" }}>
          {/* Filter input */}
          <label htmlFor=""><b>Filter By Name :</b></label>
          <input
            type="text"
            value={filterName}
            onChange={this.handleFilterChange}
            placeholder="Filter by name"
            className="form-control"
          />
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-center">Upload Date</th>
              <th className="text-center">Download</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              filteredData.length > 0 ? (
                filteredData.map((notice) => {
                  const { name, file, date } = notice;
                  return (
                    <tr key={notice.tid}>
                      <td>{name}</td>
                      <td className="text-center">
                        {Moment(date).format("DD")}{" "}
                        {Moment(date).format("MMMM")}, {Moment(date).format("YYYY")}
                      </td>
                      <td className="text-center">
                        <a
                          href={`${"http://admin.mpsckp.com/tcs/"}${file}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data found
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default GetTc;
