import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Link } from "react-router-dom";
import "../Gallery.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const AnnualDay2024 = () => {
  const title="Annual Day 2024";
  const images = [];
for (let i = 1; i <= 176; i++) {
  images.push({
    original: `http://www.mpsckp.com/gallery/annual_day_2024/${i}.jpg`,
    thumbnail: `http://www.mpsckp.com/gallery/annual_day_2024/${i}.jpg`,
  });
}


  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>{title}</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={"/mgallery"}>
                      <i className="fa fa-camera"></i> Gallery
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
            <ImageGallery items={images} lazyLoad={true}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AnnualDay2024;
