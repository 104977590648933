import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./StudentArena.css";

const StudentArena = () => {
  const [students, setStudents] = useState([]); // Store fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // Fetch data when component mounts
    fetch("https://mpsckp.com/studentarenadata.php")
      .then((response) => response.json())
      .then((data) => {
        setStudents(data); // Update state with fetched data
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((err) => {
        setError("Failed to fetch data"); // Set error state if fetch fails
        setLoading(false); // Set loading to false even in case of error
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Display loading message
  }

  if (error) {
    return <p>{error}</p>; // Display error message if any
  }

  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Student Arena</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Student Arena</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
              <p className="text-center">
                To ensure healthy and constructive competition among the
                students and to develop team spirit the students are divided
                under four group:
              </p>
              <div className="circlearea">
                {students.map((data, index) => (
                  <div key={index} className="circle">
                    {data.groupName}
                  </div>
                ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="house">
                {students.map((data, index) => (
                  <div key={index}>
                    <Row>
                      <Col>
                        <h3>{data.groupName}</h3>
                      </Col>
                    </Row>
                    <Row>
                      {data.members.map((member, idx) => (
                        <Col key={idx} md={3}>
                          <div className="sArea">
                            <img src={member.image} alt={member.name} />
                            <h6>{member.name}</h6>
                            <p>{member.designation}</p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="saline"></div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024hb.jpg"
                  alt="Head Boy"
                />
                <div className="saareadetails">
                  <p>SAMBHAV NANDA</p>
                  <p>Head Boy</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024hg.jpg"
                  alt="Head Girl"
                />
                <div className="saareadetails">
                  <p>SWETA BODRA</p>
                  <p>Head Girl</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024vhb.jpg"
                  alt="Vice Head Boy"
                />
                <div className="saareadetails">
                  <p>SHIV SHANKAR BOBONGA</p>
                  <p>Vice Head Boy</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="saarea">
                <img
                  src="https://www.mpsckp.com/images/2024vhg.jpg"
                  alt="Vice Head Girl"
                />
                <div className="saareadetails">
                  <p>LUCKY SAHU</p>
                  <p>Vice Head Girl</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StudentArena;
