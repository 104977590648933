import React from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export const GalleryCarousel = () => {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item>
          <Row>
          <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/ScienceExhibition2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/science_exhibition_2024/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/ScienceExhibition2024"}>Science Exhibition 2024</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualSports2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_sports_2024/1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualSports2024"}>Annual Sports 2024</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/AnnualDay2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/annual_day_2024/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/AnnualDay2024"}>Annual Day 2024</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            
          <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Loih2024"}>
                  <img
                    src="http://www.mpsckp.com/gallery/loih_2024/2.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Loih2024"}>Learning Outcome (In House)</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Essay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/essay_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Essay"}>Essay</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/Calligraphy"}>
                  <img
                    src="http://www.mpsckp.com/gallery/calligraphy_2022/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/Calligraphy"}>Calligraphy</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/School"}>
                  <img
                    src="http://www.mpsckp.com/gallery/school/images/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/School"}>The School</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/RepublicDay"}>
                  <img
                    src="http://www.mpsckp.com/gallery/republic_day_20/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/RepublicDay"}>Republic Day</Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="mpsgallery">
                <Link to={"/PrizeDistribution"}>
                  <img
                    src="http://www.mpsckp.com/gallery/prize_distribution_20/1.webp"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="mpsboxdetails">
                  <Link to={"/PrizeDistribution"}>Prize Distribution</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
