import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
const Privacy = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Privacy</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Privacy</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
              
            <p>Madhusudan Public School is committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application, mps, available on the Google Play Store.</p>
        <p>By using our app, you agree to the collection and use of information in accordance with this policy.</p>

        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of information when you use our app:</p>
        <ul>
            <li><strong>Personal Information:</strong> We do not collect any personally identifiable information such as your name, address, or phone number unless you voluntarily provide it for specific purposes (e.g., fill online admission application or communicating with us).</li>
            <li><strong>Usage Data:</strong> We automatically collect certain data related to how the app is used. This may include:
                <ul>
                    <li>Device information (model, operating system version, etc.)</li>
                    <li>App usage statistics (such as pages visited, interaction times, features accessed)</li>
                    <li>IP address and network information (for analytics purposes)</li>
                </ul>
            </li>
            <li><strong>Cookies and Tracking Technologies:</strong> The app may use cookies or similar technologies to track user interactions with the app and improve performance. You can control cookies through your device settings.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul>
            <li>To provide, maintain, and improve the app’s functionality.</li>
            <li>To monitor and analyze app usage and trends to enhance the user experience.</li>
            <li>To communicate with you regarding app updates, features, or important notices.</li>
            <li>To respond to inquiries and provide customer support.</li>
        </ul>

        <h2>3. Data Security</h2>
        <p>We are committed to securing your data and using commercially acceptable means to protect it. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>4. Third-Party Services</h2>
        <p>We do not sell, trade, or rent your personal information to third parties. However, we may use third-party services that help us analyze app usage or provide specific functionalities. These third-party services may have access to your personal data to perform tasks on our behalf, but they are obligated not to disclose or use it for any other purpose.</p>
        <p>These third-party services may include:</p>
        <ul>
            <li><strong>Google Analytics:</strong> Used to gather usage data to improve our services.</li>
            <li><strong>Firebase:</strong> Used for app performance, crash reporting, and user engagement.</li>
        </ul>

        <h2>5. Children’s Privacy</h2>
        <p>Our app is designed for use by students and parents. We do not knowingly collect personal information from children under the age of 13 without parental consent. If we become aware that we have inadvertently collected information from a child under the age of 13, we will take steps to delete that information as quickly as possible.</p>

        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page with an updated "Effective Date." We encourage you to review this policy periodically for any changes.</p>

        <h2>7. Your Rights and Choices</h2>
        <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
        <ul>
            <li>The right to access the personal information we hold about you.</li>
            <li>The right to correct or update your personal information.</li>
            <li>The right to request the deletion of your personal information.</li>
            <li>The right to withdraw consent for data processing at any time, where applicable.</li>
        </ul>
        <p>To exercise these rights, please contact us using the details provided below.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or wish to exercise your privacy rights, please contact us at: <a href="mailto:mpsckp@gmail.com">mpsckp@gmail.com</a></p>
        
            </Col>
          </Row>
          
        </Container>
      </div>
    </div>
  );
};

export default Privacy;
